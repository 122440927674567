import { useEffect, useState } from "react";
import * as d3 from "d3";
import moment from "moment";

import "./App.css";

import DirectedGraph from "./graph/directedGraph";
import { addressStringReduction, numberWithCommas } from "./utils/format";
import { requestVisualData } from "./utils/api";

function App() {
  const [fetchingBlockchainData, setFetchingBlockchainData] = useState(false);
  const [blockchainPayload, setBlockchainPayload] = useState<any>({});

  const fetchBlockTransactions = async () => {
    const rawData = await requestVisualData();
    setBlockchainPayload(rawData);
    setFetchingBlockchainData(false);
  };

  useEffect(() => {
    if (!fetchingBlockchainData) {
      setFetchingBlockchainData(true);
      fetchBlockTransactions();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(blockchainPayload).length > 0) {
      console.log("Diagram Data: ");
      console.log(blockchainPayload);

      const chart = DirectedGraph({
        data: {
          nodes: blockchainPayload?.addressLog?.visual,
          links: blockchainPayload?.linkageLog?.visual,
        },
        nodeId: (d: any) => d?.id,
        nodeGroup: (d: any) => d?.group,
        nodeTitle: (d: any) => `${d?.id} (${d?.group})`,
        zoom: 5,
        invalidation: null, // a promise to stop the simulation when the cell is re-run
      });

      d3.select(document.getElementById("CHART_HOLDER")).append(() => chart);
    }
  }, [blockchainPayload]);

  return (
    <div>
      {!!blockchainPayload && !!blockchainPayload.currentBlock && (
        <div className="InformationSection">
          <div className="InformationSection-title">
            {moment(blockchainPayload.currentBlock.timestamp * 1000).format(
              "MMM Do [at] h:mm a"
            )}
          </div>
          <div className="InformationSection-subtitle">Pulse Range:</div>
          <div>
            Starting at block{" "}
            <a
              href={`https://etherscan.io/block/${blockchainPayload.startingBlock}`}
              target="_blank"
              rel="noreferrer"
            >
              {numberWithCommas(blockchainPayload.startingBlock)}
            </a>
          </div>
          <div>
            Ending at block{" "}
            <a
              href={`https://etherscan.io/block/${blockchainPayload.endingBlock}`}
              target="_blank"
              rel="noreferrer"
            >
              {numberWithCommas(blockchainPayload.endingBlock)}
            </a>
          </div>
          <div className="InformationSection-subtitle">Pulse Stats:</div>
          <div>
            {numberWithCommas(blockchainPayload.addressLog.visual.length)}{" "}
            unique addresses
          </div>
          <div>
            {numberWithCommas(blockchainPayload.linkageLog.visual.length)}{" "}
            transactions
          </div>

          <div className="InformationSection-subtitle">
            Interesting Details:
          </div>
          <div>
            <a
              href={`https://etherscan.io/address/${blockchainPayload.addressWithMost}`}
              target="_blank"
              rel="noreferrer"
            >
              {addressStringReduction(blockchainPayload.addressWithMost)}
            </a>{" "}
            had the most transactions{" "}
            {numberWithCommas(blockchainPayload.linkCount)}
          </div>
        </div>
      )}
      <div id="CHART_HOLDER"></div>
    </div>
  );
}

export default App;
