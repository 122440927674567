import { BigNumber } from "ethers";

export const addressStringReduction = (str: any) => {
  let firstChunk = str.substring(0, 5);
  let lastChunk = str.substring(str.length - 4);

  return `${firstChunk}...${lastChunk}`;
};

export const numberWithCommas = (rawX: BigNumber | number | null) => {
  const x = BigNumber.from(rawX);

  if (!x) {
    return "-";
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
