// // // // // // // // // // // // // // // // // // // //
// ENVIRONMENT STATE
// // // // // // // // // // // // // // // // // // // //

const IS_STAGE =
  !!process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV === "stage";
const IS_PROD =
  !!process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV === "prod";

export const IS_LIVE_RUN = IS_PROD || IS_STAGE;

// // // // // // // // // // // // // // // // // // // //
// RPC NODE URL
// // // // // // // // // // // // // // // // // // // //

const LOCAL_API_URL = process.env
  .REACT_APP_LOCAL_API_URL
  ? process.env.REACT_APP_LOCAL_API_URL
  : "";
const STAGE_API_URL = process.env
  .REACT_APP_STAGE_API_URL
  ? process.env.REACT_APP_STAGE_API_URL
  : "";
const PROD_API_URL = process.env
  .REACT_APP_PROD_API_URL
  ? process.env.REACT_APP_PROD_API_URL
  : "";

export const API_URL = IS_PROD
  ? PROD_API_URL
  : IS_STAGE
  ? STAGE_API_URL
  : LOCAL_API_URL;
