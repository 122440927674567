import * as d3 from "d3";
import { addressStringReduction } from "../utils/format";

// const chart = DirectedGraph({
//   nodeId: (d: any) => d.id,
//   nodeGroup: (d: any) => d.group,
//   nodeTitle: (d: any) => `${d.id} (${d.group})`,
//   width: 600,
//   height: 680,
//   invalidation: null, // a promise to stop the simulation when the cell is re-run
// });

const produceDirectedGraph = ({
  data,
  zoom,
  invalidation, // when this promise resolves, stop the simulation
}: any = {}) => {
  const width = 1600;
  const height = 1600;

  const types = ["transfer"];

  const linkArc = (d: any): any => {
    const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
    return `
      M${d.source.x},${d.source.y}
      A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
    `;
  };

  const color = d3.scaleOrdinal(types, d3.schemeCategory10);

  const drag = (simulation: any): any => {
    function dragstarted(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event: any, d: any) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event: any, d: any) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  const links = data.links.map((d: any) => Object.create(d));
  const nodes = data.nodes.map((d: any) => Object.create(d));

  const simulation = d3
    .forceSimulation(nodes)
    .force(
      "link",
      d3.forceLink(links).id((d: any) => d.id)
    )
    .force("charge", d3.forceManyBody().strength(-400))
    .force("x", d3.forceX())
    .force("y", d3.forceY());

  const svg = d3
    .create("svg")
    .attr("viewBox", [-width / 2, -height / 2, width, height])
    .style("font", `${zoom * 1.75}px sans-serif`)
    .style("width", "100%")
    .style("height", "100%")
    .style("position", "fixed");

  // Per-type markers, as they don't inherit styles.
  svg
    .append("defs")
    .selectAll("marker")
    .data(types)
    .join("marker")
    .attr("id", (d) => `arrow-${d}`)
    .attr("viewBox", "0 -5 10 10")
    .attr("refX", 15)
    .attr("refY", -0.5)
    .attr("markerWidth", 6)
    .attr("markerHeight", 6)
    .attr("orient", "auto")
    .append("path")
    .attr("fill", color)
    .attr("d", "M0,-5L10,0L0,5");

  const link = svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke-width", 0.3 * zoom)
    .selectAll("path")
    .data(links)
    .join("path")
    .attr("stroke", (d: any) => color(d.type))
    .attr("marker-end", (d: any) => `url(#arrow-${d.type})`);
  // .attr("marker-end", d => `url(${new URL(`#arrow-${d.type}`, location)})`);

  const node = svg
    .append("g")
    .attr("fill", "currentColor")
    .attr("stroke-linecap", "round")
    .attr("stroke-linejoin", "round")
    .selectAll("g")
    .data(nodes)
    .join("g")
    .call(drag(simulation));

  node
    .append("circle")
    .attr("stroke", "white")
    .attr("stroke-width", 0.2 * zoom)
    .attr("r", 0.5 * zoom);

  node
    .append("text")
    .attr("x", 3)
    .attr("y", "0.31em")
    .text((d: any) => addressStringReduction(d.id))
    .clone(true)
    .lower()
    .attr("fill", "none")
    .attr("stroke", "white")
    .attr("stroke-width", 1.5);

  simulation.on("tick", () => {
    link.attr("d", linkArc);
    node.attr("transform", (d: any) => `translate(${d.x},${d.y})`);
  });

  // Handle invalidation.
  if (invalidation != null) invalidation.then(() => simulation.stop());

  /////////////////////////

  const handleZoom = (e: any) => {
    svg.selectAll("g").attr("transform", e.transform);
  };

  // .attr("viewBox", [-width / 2, -height / 2, width, height])

  let zoomD3: any = d3.zoom().on("zoom", handleZoom);

  const initZoom = () => {
    d3.select("svg").call(zoomD3);
  };

  initZoom();
  /////////////////////////

  return svg.node();
};

export default produceDirectedGraph;
